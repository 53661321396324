<template>
    <base-layout>

        <div class="page-tv-devices-local">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <app-table
                            class="local-devices-table"

                            :sort-value="filter.sort"
                            :sort-options="sort"
                            @sort-change="onSortChange"

                            :cols="cols"
                            :rows="rows"

                            :loading="loading"

                            :selected-row="device_uuid"

                            @open-sidebar="onDevicesRowClick"
                            @row-click-mobile="openDevicedetails"
                        />

                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />
                    </div>
                </div>
            </div>
        </div>

        <template v-slot:sidebar>
            <app-sidebar-details title="Customer details" @close-sidebar="closeSidebar">
                <app-device-details :uuid="customer_uuid" @close-sidebar="closeSidebar"/>
            </app-sidebar-details>
        </template>

    </base-layout>
</template>

<script>
import baseLayout from '@/views/layouts/base-layout'

import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import appSidebarDetails from '@/components/app-sidebar-details'
import appDeviceDetails from './components/app-device-details'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        baseLayout,

        appTable,
        appPagination,

        appSidebarDetails,
        appDeviceDetails,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'Name',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Name',             title: 'Name'         },
                { field: 'SerialNumber',     title: 'Serial №'     },
                { field: 'HouseholdID',      title: 'Household ID' },
                { field: 'DeviceFamilyName', title: 'Family'       },
            ],

            cols: [
                { key: 'name',          title: 'Name',         sort: { field: 'Name' },             highlight: true,    },
                { key: 'serial-number', title: 'Serial №',     sort: { field: 'SerialNumber' },                         },
                { key: 'household-id',  title: 'Household ID', sort: { field: 'HouseholdID' },                          },
                { key: 'type',          title: 'Type',                                                                  },
                { key: 'family',        title: 'Family',       sort: { field: 'DeviceFamilyName' },                     },
                { key: 'link',                                 action: 'open-sidebar', icon: 'link', behavior: 'hidden' },
            ],
            rows: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            device_uuid: null,

            customer_uuid: null,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getLocalDevices()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getLocalDevices()
        },

        onPageChange(page) {
            this.getLocalDevices(page)
        },

        getLocalDevices(page) {
            this.loading = true

            const filter = {
                ...this.filter,
            }

            const params = {
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('getLocalDevices', { params })
                .then(({ PageInfo, LocalDevices }) => {
                    console.log(PageInfo)
                    console.log(LocalDevices)

                    this.rows = LocalDevices.map( device => ({
                        uuid: device.UUID,
                        'customer-uuid': device.CustomerUUID,
                        name: device.Name,
                        'serial-number': device.SerialNumber,
                        'household-id': device.HouseholdID,
                        type: `${device.DeviceFamilyID}/${device.DeviceBrandID}`,
                        family: device.DeviceFamilyName,
                    }))

                    const { DisplayPageList, PageNumber, TotalItemCount } = PageInfo

                    this.pagination.page = PageNumber
                    this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                    }

                    this.loading = false
                })
                .catch(error => {
                    console.log(error)

                    this.rows = []

                    this.loading = false
                })
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.device_uuid = null
            this.customer_uuid = null

            this.$emit('close-sidebar')
        },

        onDevicesRowClick(row) {
            if (this.device_uuid == row.uuid && this.customer_uuid == row['customer-uuid']) {
                this.closeSidebar()
            } else {
                this.device_uuid = row.uuid
                this.customer_uuid = row['customer-uuid']
    
                this.openSidebar()
            }
        },

        openDevicedetails(row) {
            this.$router.push({ name: 'devices-local-details', params: { uuid: row['customer-uuid'] } })
        },
    },
}
</script>

<style lang="scss">
.page-tv-devices-local {
    padding-bottom: 80px;

    .local-devices-table {
        @include table-cols-width((200px, 200px, 200px, 100px, 200px, 48px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-tv-devices-local {
        .local-devices-table {
            @include table-cols-width((100px, 100px, 100px, 100px, 100px, 48px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-tv-devices-local {
        .local-devices-table {
            @include table-cols-width-mobile((90px, 160px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>