<template>
    <div class="app-device-details">
        <app-loader v-if="loading.customer || loading.devices"></app-loader>

        <div class="base-info">
            <router-link v-if="uuid" class="name" :to="{ name: 'devices-local-details', params: { uuid } }">
                <span>{{ fullname }}</span>
            </router-link>

            <div v-else class="name">
                <span>{{ fullname }}</span>
            </div>

            <div class="status">Account status: <span class="label" :class="[status.value]">{{ status.text }}</span></div>
        </div>

        <app-table-details
            :rows="rows.customer"
            :data="customer"

            title="Personal information"
        />

        <app-devices-list :devices="devices" />

        <router-link v-if="uuid" class="btn btn-secondary btn-full-width" :to="{ name: 'devices-local-details', params: { uuid } }">Go to customer page</router-link>
        <button v-else class="btn btn-secondary btn-full-width" disabled>Go to customer page</button>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'

import appDevicesList from '../../../../components/app-devices-list'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
        appDevicesList,
    },

    data() {
        return {
            loading: {
                customer: false,
                devices: false,
            },

            customer: {},
            devices: [],

            rows: {
                customer: [
                    { key: 'FirstName',    title: 'First name'    },
                    { key: 'LastName',     title: 'Last name'     },
                    { key: 'MobileNumber', title: 'Mobile number' },
                    { key: 'Email',        title: 'E-mail'        },
                    { key: 'UUID',         title: 'Account ID'    },
                ]
            },
        }
    },

    computed: {
        customer_uuid() {
            return this.uuid
        },

        fullname() {
            const fullname = [this.customer.FirstName, this.customer.LastName].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        status() {
            return this.loading.customer ? {
                text: 'Loading',
                value: 'info',
            } : (
                this.customer.Active ? {
                    text: 'Active',
                    value: 'success',
                } : {
                    text: 'Inactive',
                    value: 'danger',
                }
            )
        },
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getCustomerDetails()

            this.getCustomerDevices()
        },

        getCustomerDetails() {
            if (this.customer_uuid) {
                this.loading.customer = true

                this.$store.dispatch('getCustomerByUUID', { uuid: this.customer_uuid })
                    .then(customer => {
                        this.customer = customer

                        this.loading.customer = false
                    })
                    .catch(error => {
                        console.log(error)

                        this.loading.customer = false

                        this.closeSidebar()
                    })
            }
        },

        getCustomerDevices() {
            if (this.customer_uuid) {
                this.loading.devices = true

                this.$store.dispatch('getCustomerDevices', { uuid: this.customer_uuid })
                    .then(devices => {
                        console.log(devices)

                        this.devices = devices

                        this.loading.devices = false
                    })
                    .catch(error => {
                        console.log(error)
                        this.loading.devices = false
                    })
            }
        },

        closeSidebar() {
            this.$emit('close-sidebar')
        },
    },

    watch: {
        customer_uuid() {
            this.init()
        },
    },
}
</script>

<style lang="scss">
.app-device-details {
    position: relative;
    min-height: 100%;
    padding: 0 32px 40px;

        .base-info {
            display: flex;
            justify-content: space-between;
            padding: 36px 0 64px;

            .name {
                display: flex;
                flex: 1;
                position: relative;
                min-width: 0;
                max-width: fit-content;
                margin-right: 32px;
                padding-right: 8px + 18px;
                font-size: 32px;
                font-weight: bold;
                line-height: 1.25;
                color: var(--color-sidebar-link);
                transition: color $transition-duration-primary;
                cursor: pointer;

                span {
                    @include text-overflow();
                }

                &::before {
                    position: absolute;
                    right: 0;
                    bottom: 5px;
                    display: block;
                    width: 24px;
                    height: 24px;

                    color: var(--color-icon-action);

                    @include icon($icon-account-details);
                }


                &::after {
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    display: block;
                    width: 0;
                    height: 4px;
                    background-color: var(--color-tab-hover);
                    border-radius: $border-radius-primary;
                    transition: width $transition-duration-primary;
                    content: '';
                }

                &:hover {
                    color: var(--color-tab-hover);

                    &::after {
                        width: 80px;
                    }
                }
            }

            .status {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
                line-height: 1.5;
                color: var(--color-sidebar-text);

                .label {
                    margin-left: 16px;
                    padding: 4px 12px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    text-align: center;
                    border-radius: $border-radius-primary;

                    /* @todo Использовать @each и map-get */

                    &.success {
                        background-color: var(--color-success-bg);
                        color: var(--color-success);
                    }

                    &.info {
                        background-color: var(--color-info-bg);
                        color: var(--color-info);
                    }

                    &.warning {
                        background-color: var(--color-warning-bg);
                        color: var(--color-warning);
                    }

                    &.danger {
                        background-color: var(--color-danger-bg);
                        color: var(--color-danger);
                    }
                }
            }
        }

        .app-table-details,
        .app-devices-list {
            margin-bottom: 30px;

            h2 {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .app-devices-list {
            h3 {
                font-size: 16px;
                line-height: 24px;
            }
        }
}

@media (max-width: $tablet-size) {
    .app-device-details {
        padding: 0 40px 40px;

        .base-info {
            padding: 32px 0 64px;

            .name {
                margin-right: 21px;
            }

            .status {
                font-size: 14px;

                .label {
                    margin-left: 8px;
                    padding: 2px 8px;

                    font-size: 12px;

                    text-transform: uppercase;
                }
            }
        }
    }
}
</style>